import request from "@/utils/request";

export function getPublicParams(data) {
  return request({
    url: "api/v2/gzh/getPublicParams",
    method: "post",
    data,
  });
}

export function savePublucParams(data) {
  return request({
    url: "api/v2/gzh/savePublucParams",
    method: "post",
    data,
  });
}

export function updatePublucParams(data) {
  return request({
    url: "api/v2/gzh/updatePublucParams",
    method: "post",
    data,
  });
}

export function initMenu(data) {
  return request({
    url: "api/v2/gzh/initMenu",
    method: "post",
    data,
  });
}

export function sendTemplateMsg(data) {
  return request({
    url: "/api/v2/wx/sendTemplateMsg",
    method: "post",
    data,
  });
}

export function getMenu(data) {
  return request({
    url: "api/v2/gzh/getMenu",
    method: "post",
    data,
  });
}

//群发消息
export function sendMassMsg(data) {
  return request({
    url: "api/v2/gzh/sendMassMsg",
    method: "post",
    data,
  });
}

//获取头像
export function getGzhInfo(data) {
  return request({
    url: "api/v2/gzh/getGzhInfo",
    method: "post",
    data,
  });
}

//发送客服消息
export function sendCustomerMessage(data) {
  return request({
    url: "api/v2/gzh/sendCustomerMessage",
    method: "post",
    data,
  });
}

//公众号状态
export function getServerStatus(data) {
  return request({
    url: "api/v2/gzh/getServerStatus",
    method: "post",
    data,
  });
}