<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="结束/终止合同"
    width="450px"
    destroy-on-close
  >
    <el-form :model="form" size="small" label-width="100px">
      <el-form-item label="合同状态">
        <el-radio-group v-model="form.status">
          <el-radio :label="3">结束</el-radio>
          <el-radio :label="4">终止</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="结束/终止日期">
        <el-date-picker
          size="small"
          value-format="YYYYMMDD"
          v-model="form.date"
          type="date"
          placeholder="起始日期"
        />
      </el-form-item>
      <el-form-item label="结束/终止原因">
        <el-input v-model="form.remark" class="w300" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitSure"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage, dayjs } from "element-plus";
import { submitApproval, contractApprove } from "@/api/newCrm";

const emit = defineEmits(['']);
const dialogFormVisible = ref(false);
const form = ref({
  id: null,
  date: '',
  status: 3,
  remark: "审核通过",
});
const options = ref([]);
const init = async (item) => {
  form.value.id = item.map((v) => {
    return v.id;
  });
  await submitApproval({ id: item[0].id, type: 2 }).then((res) => {
    if (res.data.msg == "success") {
      options.value = res.data.data.list;
    }
  });
  dialogFormVisible.value = true;
};
const submitSure = () => {
  if (!form.value.status) {
    ElMessage.warning("请选择合同状态");
  } else {
    let param = {
      bingingId: form.value.id,
      type: 2,
      status: form.value.status,
      remark: form.value.remark,
      date: dayjs(form.value.date).format('YYYY-MM-DD'),
    };

    contractApprove(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("结束/终止成功");
        dialogFormVisible.value = false;
        emit("success");
      }
    });
  }
};
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.w300 {
  width: 300px;
}
.margin0 {
  margin: 0;
}
</style>
