<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="审核"
    width="450px"
    destroy-on-close
  >
    <el-form :model="form" size="small" label-width="70px">
      <el-form-item>
        <el-radio-group v-model="form.status" @change="handleChange">
          <el-radio :label="2">审核通过</el-radio>
          <el-radio :label="6">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.status === 2 && options.length" label="选下一人">
        <select-common
          placeholder="请指定下一审核人"
          v-model:modelValue="form.userId"
          :options="options"
          valueKey="userId"
          labelKey="userName"
          class="w300 margin0"
        />
      </el-form-item>
      <el-form-item label="批语">
        <el-input v-model="form.remark" class="w300" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitSure"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { submitApproval, contractApprove } from "@/api/newCrm";
const emit = defineEmits();
const dialogFormVisible = ref(false);
const form = ref({
  id: null,
  userId: null,
  status: 2,
  remark: "审核通过",
});
const options = ref([]);
const init = async (item) => {
  form.value.id = item.map((v) => {
    return v.id;
  });
  form.value.userId = null;
  await submitApproval({ id: item[0].id, type: 2 }).then((res) => {
    if (res.data.msg == "success") {
      options.value = res.data.data.list;
    }
  });
  dialogFormVisible.value = true;
};
const submitSure = () => {
  if (!form.value.userId && form.value.status == 2 && options.value.length) {
    ElMessage.warning("请指定审批人");
  } else {
    const info = options.value.find((item) => item.userId == form.value.userId);
    let param = {
      bingingId: form.value.id,
      type: 2,
      status: form.value.status,
      remark: form.value.remark,
      req: info?.req,
      userName: info?.userName,
      userId: form.value.userId,
    };
    contractApprove(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("提交成功");
        dialogFormVisible.value = false;
        emit("success");
      }
    });
  }
};
const handleChange = () => {
  if (form.value.status == 2) {
    form.value.userId = null;
    submitApproval({ id: form.value.id[0], type: 2 }).then((res) => {
      if (res.data.msg == "success") {
        options.value = res.data.data.list;
      }
    });
    form.value.remark = "审核通过";
  } else {
    form.value.userId = null;
    options.value = [];
    form.value.remark = "驳回";
  }
}
defineExpose({
  init,
});
</script>
<style lang="scss" scoped>
.w300 {
  width: 300px;
}
.margin0 {
  margin: 0;
}
</style>
