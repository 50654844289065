<template>
  <div style="padding: 10px 20px" @click="clear">
    <div class="z_div" v-loading="loading">
      <div class="left_fixed">
        <div style="padding: 10px">
          <img :src="pic" alt="" style="width: 30px" />
        </div>
        <div
          style="margin: 10px 0 20px 0; cursor: pointer"
          @click="switchType('msg')"
        >
          <i
            class="iconfont icon-xiaoxi"
            style="color: #95ec69; font-size: 20px"
            v-if="type == 'msg'"
          ></i>
          <i
            class="iconfont icon-weixin"
            v-else
            style="color: #999; font-size: 20px"
          ></i>
        </div>
        <div style="cursor: pointer" @click="switchType('person')">
          <i
            class="iconfont icon-renyuanliebiao"
            style="color: #95ec69; font-size: 20px"
            v-if="type == 'person'"
          ></i>
          <i
            class="iconfont icon-renyuanguanli1"
            v-else
            style="font-size: 20px"
          ></i>
        </div>
        <div
          style="position: absolute; bottom: 20px; left: 15px; cursor: pointer"
          @click="install_func"
        >
          <i class="iconfont icon-guanlishezhi" style="font-size: 20px"></i>
        </div>
      </div>
      <div
        class="list_left_div"
        :class="currentIndex != 0 ? 'list_left_divactive' : ''"
      >
        <div class="top_select">
          <div style="display: flex; align-items: center">
            <el-input
              placeholder="请输入昵称/公司名"
              v-model="listQuery.name"
              style="width: 270px; height: 30px"
              class="filter-item"
              size="small"
              prefix-icon="Search"
              @keyup.enter="getList"
              @input="findlist"
            />
            <el-icon
              size="22px"
              color="#999"
              style="margin-left: 10px"
              @click="dialogVisiblelistclick"
              ><CirclePlus
            /></el-icon>
            <!-- <el-button
              class="filter-item1"
              style="height: 30px"
              type="primary"
              size="small"
              @click="getList"
            >
              <el-icon><Search /></el-icon><span> 搜索</span>
            </el-button> -->
          </div>
        </div>

        <div class="choose_list" v-if="type == 'msg'">
          <el-scrollbar :height="contentStyleObj">
            <el-empty
              description="暂无人员"
              v-if="tableData.length == 0"
            ></el-empty>
            <div
              class="list_div"
              v-for="(item, index) in tableData"
              :key="index"
              @click="click_choose(item, index)"
              :class="{ actived: currentIndex === index }"
            >
              <div class="div_box">
                <div style="margin: 0 5px">
                  <img :src="item.avatarUrl" alt="" style="width: 40px" />
                </div>
                <div>
                  <p class="nick_name">{{ item.nickname }}</p>
                  <p class="com_style">{{ item.name }}</p>
                </div>
              </div>
              <div
                style="display: flex; align-items: center; margin-right: 10px"
                @click="clearclose(item)"
              >
                <!-- <span class="com_style2">取消绑定</span> -->
                <el-icon size="15px" color="#F56C6C" style="margin-left: 5px"
                  ><DeleteFilled
                /></el-icon>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="choose_list" v-else style="padding-left: 15px">
          <el-scrollbar :maxHeight="contentStyleObj">
            <el-checkbox-group
              v-model="checkedTemp"
              @change="getChangeInfo"
              style="width: 300px"
            >
              <el-checkbox
                v-for="item in list"
                :label="item"
                :key="item.openid"
              >
                <div class="div_box">
                  <div style="margin: 0 5px">
                    <img :src="item.avatarUrl" alt="" style="width: 40px" />
                  </div>
                  <div>
                    <p class="nick_name" style="width: 220px">
                      {{ item.nickname }}
                    </p>
                    <p class="com_style" style="width: 220px">
                      {{ item.name }}
                    </p>
                  </div>
                </div></el-checkbox
              >
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>
      <div class="right_div" :style="{ width: windowWeight + 'px' }">
        <div class="right_div_buttondiv">
          <div
            style="font-weight: bold; padding-left: 20px; font-size: 14px"
            v-if="type == 'msg'"
          >
            {{ title ? title : "暂未选择" }}
          </div>
          <div
            style="font-weight: bold; padding-left: 20px; font-size: 14px"
            v-else
          >
            发送给
          </div>
        </div>
        <!-- <div class="title_div">
          <span style="font-weight: bold"></span>
        </div> -->
        <div
          :class="historyarr.length == 0 ? '' : 'on_val_div'"
          v-if="type == 'msg'"
        >
          <el-scrollbar :height="contentStyleObj1" id="scroll">
            <el-empty
              description="暂无消息"
              v-if="historyarr.length == 0"
            ></el-empty>
            <div id="scrollMsg">
              <div
                class="on_clounm"
                style="padding-top: 10px"
                v-for="(item, index) in historyarr"
                :key="index"
                :class="item.isSend == 1 ? '' : 'newsdiv'"
              >
                <div
                  style="width: 100%"
                  :class="item.isSend == 1 ? '' : 'newsdiv'"
                >
                  <div class="time_div">
                    <p>{{ item.createdAt }}</p>
                  </div>
                  <div v-if="item.isSend == 1">
                    <div
                      @click="itemlick(item)"
                      v-if="
                        item.msgType == 'template_msg' &&
                        item.message.keyword3 == undefined
                      "
                      style="display: flex; justify-content: center"
                    >
                      <div class="dives">
                        <p class="title_p">{{ item.templateName }}</p>
                        <p class="val_p">
                          <span style="font-weight: 600">服务项目:</span
                          >&nbsp;&nbsp;<span
                            class="span_val"
                            v-if="item.msgType != 'text'"
                            >{{ item.message.thing2.value }}</span
                          >
                          <span class="span_val" v-else>{{
                            item.message
                          }}</span>
                        </p>
                        <p class="val_p" style="margin-left: 0px">
                          <span style="font-weight: 600">办理事项:</span
                          ><span
                            class="span_val"
                            style="margin-left: 10px"
                            v-if="item.msgType != 'text'"
                            >{{ item.message.thing5.value }}</span
                          >
                          <span class="span_val" v-else>{{
                            item.message
                          }}</span>
                        </p>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        item.msgType == 'template_msg' &&
                        item.message.keyword3 != undefined
                      "
                      style="display: flex; justify-content: center"
                    >
                      <div class="dives">
                        <p class="title_p">{{ item.templateName }}</p>
                        <p class="val_p">
                          <span style="font-weight: 600">工作内容:</span
                          >&nbsp;&nbsp;<span
                            class="span_val"
                            v-if="item.msgType != 'text'"
                            >{{ item.message.keyword1.value }}</span
                          >
                          <span class="span_val" v-else>{{
                            item.message
                          }}</span>
                        </p>
                        <p class="val_p" style="margin-left: 0px">
                          <span style="font-weight: 600">税款:</span
                          ><span
                            class="span_val"
                            style="margin-left: 35px"
                            v-if="item.msgType != 'text'"
                            >{{ item.message.keyword3.value }}</span
                          >
                          <span class="span_val" v-else>{{
                            item.message
                          }}</span>
                        </p>
                        <p class="val_p" style="margin-left: 0px">
                          <span style="font-weight: 600">当前状态:</span
                          ><span
                            class="span_val"
                            style="margin-left: 10px"
                            v-if="item.msgType != 'text'"
                            >{{ item.message.keyword2.value }}</span
                          >
                          <span class="span_val" v-else>{{
                            item.message
                          }}</span>
                        </p>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-else-if="
                        item.msgType == 'gzh_text' &&
                        item.message.keyword3 == undefined
                      "
                      style="
                        display: flex;
                        justify-content: end;
                        align-items: center;
                      "
                    >
                      <!-- <div
                      style="display:flex;align-items: center;margin-right:10px;"
                    > -->
                      <div
                        class="dives dives_left"
                        style="margin-left: 10px; background: white"
                      >
                        <p
                          class="val_p"
                          v-if="item.msgType == 'gzh_text'"
                          style="width: 260px; word-wrap: break-word"
                        >
                          {{ item.message }}
                        </p>
                      </div>
                      <img
                        :src="item.avatarUrl"
                        alt=""
                        style="width: 30px; margin: 0 10px; height: 30px"
                      />
                      <!-- </div> -->
                    </div>
                  </div>
                  <!-- 下面展示的是isSend为2的,也就接收消息的 显示为绿色-->
                  <div v-else style="display: flex; align-items: center">
                    <img
                      :src="item.avatarUrl"
                      alt=""
                      style="width: 30px; margin-left: 10px"
                    />
                    <div class="dives dives_left" style="margin-left: 10px">
                      <p
                        class="val_p"
                        v-if="item.msgType == 'text'"
                        style="width: 260px; word-wrap: break-word"
                      >
                        {{ item.message }}
                      </p>
                      <p v-else style="width: 260px; word-wrap: break-word">
                        {{ item.message }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div v-else class="on_val_div">
          <el-scrollbar :height="contentStyleObj1">
            <div
              class="list_div"
              v-for="(item, index) in checkedTemp"
              :key="index"
              style="border: none"
            >
              <div class="div_box">
                <div style="margin: 0 5px">
                  <img :src="item.avatarUrl" alt="" style="width: 40px" />
                </div>
                <div>
                  <p class="nick_name">{{ item.nickname }}</p>
                  <p class="com_style">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div
          style="height: 250px"
          :class="historyarr.length == 0 ? 'activeEs' : ''"
        >
          <div class="under_div" v-show="under_divNumber == 1">
            <div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 725px;
                "
              >
                <div style="display: flex; align-items: center">
                  <div class="under_on_div" v-if="type == 'msg'">
                    <span
                      class="hint"
                      v-for="(item, index) in templatearr"
                      :key="index"
                      @click="choose(item, index)"
                      :class="{ activedSpan: number === index }"
                      >{{ item.templateName }}</span
                    >
                  </div>
                  <div class="under_on_div" v-else>
                    <span
                      class="hint"
                      v-for="(item, index) in templateData"
                      :key="index"
                      @click="choose(item, index)"
                      :class="{ activedSpan: number === index }"
                      >{{ item.templateName }}</span
                    >
                  </div>
                </div>
                <el-icon color="#999" size="20px" @click="showhint"
                  ><WarningFilled
                /></el-icon>
              </div>
              <div class="all_div">
                <div class="left_div" v-show="number == 0">
                  <div class="h_div">
                    <!-- <p class="val_p">工作内容</p> -->
                    <el-input
                      placeholder="标题"
                      v-model="form.keyword1"
                      clearable
                      class="inout first_textarea"
                      size="small"
                      type="textarea"
                      :rows="2"
                      maxlength="20"
                    >
                    </el-input>
                    <!-- <el-input
                    placeholder="请输入内容"
                    v-model="form.keyword1"
                    clearable
                    class="inout textarea"
                    type="textarea"
                    :rows="2"
                    
                  >
                  </el-input> -->
                  </div>
                  <!--  -->
                  <div class="h_div">
                    <!-- <p class="val_p">当前状态</p> -->
                    <el-input
                      placeholder="内容"
                      v-model="form.keyword2"
                      clearable
                      class="inout second_textarea"
                      size="small"
                      :rows="2"
                      maxlength="20"
                    >
                    </el-input>
                  </div>
                </div>
                <div
                  class="left_div"
                  v-show="number == 1 && templatearr.length == 3"
                >
                  <div class="h_div">
                    <p class="val_p">报税月份</p>
                    <el-input
                      placeholder="请输入内容"
                      v-model="form2.keyword1"
                      clearable
                      class="inout"
                      size="small"
                    >
                    </el-input>
                  </div>
                  <div class="h_div">
                    <p class="val_p" style="margin-left: 26px">税款</p>
                    <el-input
                      placeholder="请输入内容"
                      v-model="form2.keyword2"
                      clearable
                      class="inout"
                      size="small"
                    >
                    </el-input>
                  </div>
                  <div class="h_div">
                    <p class="val_p">专属会计</p>
                    <el-input
                      placeholder="请输入内容"
                      v-model="form2.keyword3"
                      clearable
                      size="small"
                      class="inout"
                    >
                    </el-input>
                  </div>
                </div>
                <div
                  class="left_div"
                  v-show="
                    number == 1 && type == 'msg' && templatearr.length == 2
                  "
                >
                  <div class="h_div" v-if="!customerMsgStatus">
                    <p
                      class="val_p"
                      style="font-size: 11px; color: red; align-items: center"
                    >
                      <i
                        class="iconfont icon-gantanhao1"
                        style="font-size: 11px; color: red; margin-right: 4px"
                      ></i>
                      不可发送客服消息，因为对方24小时内未给该公众号发送过消息
                    </p>
                  </div>
                  <div class="h_div">
                    <el-input
                      placeholder="请输入内容"
                      v-model="textarea_val"
                      clearable
                      class="inout textarea"
                      type="textarea"
                      :rows="2"
                      :disabled="!customerMsgStatus"
                    >
                    </el-input>
                  </div>
                </div>
                <!--  -->
                <div
                  class="left_div"
                  v-show="
                    number == 1 && type == 'person' && templatearr.length == 2
                  "
                >
                  <!-- <div class="h_div" v-if="!customerMsgStatus">
                  <p
                    class="val_p"
                    style="font-size: 11px; color: red; align-items: center"
                  >
                    <i
                      class="iconfont icon-gantanhao1"
                      style="font-size: 11px; color: red; margin-right: 4px"
                    ></i>
                    不可发送客服消息，因为对方24小时内未给你发送过消息
                  </p>
                </div> -->
                  <div class="h_div">
                    <el-input
                      placeholder="请输入内容"
                      v-model="form.keyword1"
                      clearable
                      class="inout textarea"
                      type="textarea"
                      :rows="2"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_dives">
              <div>
                <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="makeSurePushKf"
                  icon="Promotion"
                  v-if="
                    number == 1 &&
                    type == 'msg' &&
                    customerMsgStatus != false &&
                    templatearr.length == 2
                  "
                  style="position: relative"
                  >发送</el-button
                >
                <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="makeSurePush"
                  icon="Promotion"
                  v-if="number != 1 && type == 'msg' && templatearr.length == 2"
                  >发送</el-button
                >
                <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="sendSimpleMsg"
                  icon="Promotion"
                  v-if="
                    type == 'person' && number == 1 && templatearr.length == 2
                  "
                  :disabled="checkedTemp.length < 2"
                  >发送</el-button
                >
                <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="sendMsgPersonTemplate"
                  icon="Promotion"
                  v-if="
                    type == 'person' && number != 1 && templatearr.length == 2
                  "
                  :disabled="checkedTemp.length < 2"
                  >发送</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="绑定设置" v-model="dialogFormVisible" width="1120px">
    <div class="dialog_btn_div">
      <el-button :type="btn_type == 0 ? 'primary' : ''" @click="btn_choose(0)"
        >公众号菜单</el-button
      >
      <el-button :type="btn_type == 1 ? 'primary' : ''" @click="btn_choose(1)"
        >公众号授权信息</el-button
      >
    </div>
    <div v-show="choose_show == 1" style="margin-top: 20px">
      <!--  -->
      <!-- <div v-show="bindshow == 2" style="width: 500px">
        <el-form>
          <el-form-item label="Appid:">
            <span style="font-weight: 600">{{ bindlist.appId }}</span>
          </el-form-item>
          <el-form-item label="Secret:">
            <span style="font-weight: 600">{{ bindlist.secret }}</span>
          </el-form-item>
          <el-form-item label="Token:">
            <span style="font-weight: 600">{{ bindlist.token }}</span>
          </el-form-item>
          <el-form-item label="公众号头像:">
            <img
              :src="bindlist.avatarUrl"
              alt=""
              style="width: 150px; height: 150px"
              v-show="bindlist.avatarUrl != ''"
            />
          </el-form-item>
        </el-form>
        <div class="footer_div">
          <span class="dialog-footer">
            <el-button type="primary" size="small" @click="update_wx(0, 'add')"
              >创建绑定</el-button
            >
          </span>
        </div>
      </div> -->
      <div style="width: 500px">
        <el-form :inline="true" style="display: flex; flex-direction: column">
          <el-form-item label="Appid:">
            <el-input
              v-model="bindform.keyword1"
              style="width: 270px; margin-left: 7px"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="Secret:">
            <el-input
              v-model="bindform.keyword2"
              style="width: 270px; margin-left: 7px"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="Token:">
            <el-input
              v-model="bindform.keyword3"
              style="width: 270px; margin-left: 10px"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="公众号头像:" v-if="bindlist.avatarUrl == ''">
            <upload @uploadimgzj="imgUrl" style="margin-left: 0" />
          </el-form-item>
          <el-form-item label="公众号头像:" v-else>
            <upload
              @uploadimgzj="imgUrl"
              :images="bindlist.avatarUrl"
              style="margin-left: 0"
            />
          </el-form-item>
        </el-form>
        <div class="footer_div">
          <span class="dialog-footer">
            <el-button type="primary" size="small" @click="update_ok"
              >确定绑定</el-button
            >
          </span>
        </div>
      </div>
    </div>
    <!-- <hr> -->
    <div class="underdiv" v-show="choose_show == 0">
      <div class="header_div">
        <div class="left_screen">
          <div class="screen_header">
            <span class="time">1:21</span>
            <div class="righhtdiv">
              <img
                src="../../assets/xinhao.png"
                alt=""
                style="margin-right: 5px"
              />
              <img
                src="../../assets/wifi.png"
                alt=""
                style="margin-right: 5px"
              />
              <img src="../../assets/dianchi.png" alt="" />
            </div>
          </div>
          <div class="key_div">
            <img
              src="../../assets/jianpan.png"
              alt=""
              style="width: 30px; height: 30px; margin-left: 20px"
            />
            <div
              class="add_menu"
              @click="add_divclick"
              v-show="parent_add == 1"
            >
              <img
                src="../../assets/add_btn.png"
                alt=""
                style="width: 15px; height: 15px"
              />
              <span class="val_span">添加菜单</span>
            </div>
            <div class="add_div" v-show="parent_add == 0">
              <div
                class="child_div"
                v-for="(item, index) in divarr"
                :key="index"
              >
                <div class="child_list">
                  <div
                    v-for="(itemes, indexes) in item.sub_button"
                    :key="indexes"
                  >
                    <scroll-pane class="tags-view-wrapper">
                      <div class="heng" v-show="index == divnum">
                        <el-icon
                          style="
                            margin-left: -13px;
                            position: relative;
                            right: 5px;
                          "
                          @click.stop="deletes(itemes, indexes, index)"
                          v-show="childnum == indexes"
                          ><Delete
                        /></el-icon>
                        <div
                          class="child_box childclass el-dropdown-link"
                          @click="chooseChild(indexes, index)"
                          :class="childnum == indexes ? 'divActive' : ''"
                          v-show="divnum == index"
                        >
                          <span
                            :class="itemes.length == 4 ? 'span_class' : ''"
                            >{{ itemes.name }}</span
                          >
                        </div>
                      </div>
                      <ul
                        v-show="visible == true"
                        :style="{ left: left + 'px', top: top + 'px' }"
                        class="contextmenu"
                        id="menu"
                        ref="menu"
                      >
                        <li @click.stop="deletes(itemes, indexes, index)">
                          删除
                        </li>
                      </ul>
                    </scroll-pane>
                  </div>
                  <div
                    class="child_box"
                    @click="add_child"
                    v-show="divnum == index && item.sub_button.length != 5"
                  >
                    <span style="color: #b3b5bb; font-size: 20px">+</span>
                    <span style="font-size: 13px; margin-left: 5px">添加</span>
                  </div>
                </div>

                <div class="shu">
                  <div
                    class="add_div_box"
                    :class="divnum == index ? 'divActive' : ''"
                    @click="choose_div(index)"
                  >
                    <scroll-pane class="tags-view-wrapper">
                      <div v-show="index == 0" @click="dad_click(1, item)">
                        <span v-if="form_val.menu_input == ''">{{
                          item.name
                        }}</span>
                        <span v-else>{{ form_val.menu_input }}</span>
                      </div>
                      <div v-show="index == 1" @click="dad_click(2, item)">
                        <span v-if="form_val.menu_input2 == ''">{{
                          item.name
                        }}</span>
                        <span v-else>{{ form_val.menu_input2 }}</span>
                      </div>
                      <div v-show="index == 2" @click="dad_click(3, item)">
                        <span v-if="form_val.menu_input3 == ''">{{
                          item.name
                        }}</span>
                        <span v-else>{{ form_val.menu_input3 }}</span>
                      </div>
                    </scroll-pane>
                  </div>
                  <el-icon
                    style="position: relative; top: 20px"
                    v-show="divnum == index"
                    @click="del2(item, index)"
                    ><Delete
                  /></el-icon>
                </div>
              </div>

              <div
                class="add_div_box"
                style="background: white"
                @click="add_divclick2"
                v-show="add_show == 1"
              >
                <span style="color: #b3b5bb; font-size: 20px">+</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right_screen">
          <div class="no_menu_div" v-show="parent_add == 1 && child_id == 0">
            <span class="tit_span"
              >你未添加自定义菜单，点击左侧添加菜单为公众号创建菜单栏。</span
            >
            <div class="line_div"></div>
          </div>
          <div
            class="dad_div"
            v-show="parent_add == 0 && child_id == 0 && divnum == 0"
          >
            <h1>菜单信息</h1>
            <el-form
              ref="ruleForm"
              label-width="60px"
              style="margin-top: 20px"
              :rules="rules"
              :model="form_val"
            >
              <el-form-item label="名称" prop="menu_input">
                <el-input
                  style="width: 180px"
                  v-model="form_val.menu_input"
                  @input="dad_input(1)"
                  :maxlength="4"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="line_div"></div>
          </div>
          <!--  -->
          <div
            class="dad_div"
            v-show="parent_add == 0 && child_id == 0 && divnum == 1"
          >
            <h1>菜单信息</h1>
            <el-form ref="form" label-width="60px" style="margin-top: 20px">
              <el-form-item label="名称" prop="menu_input2">
                <el-input
                  style="width: 180px"
                  v-model="form_val.menu_input2"
                  :maxlength="4"
                  @input="dad_input(2)"
                  v-model.trim="ruleForm.gender"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="line_div"></div>
          </div>
          <!--  -->
          <div
            class="dad_div"
            v-show="parent_add == 0 && child_id == 0 && divnum == 2"
          >
            <h1>菜单信息</h1>
            <el-form ref="form" label-width="60px" style="margin-top: 20px">
              <el-form-item label="名称" prop="menu_input3">
                <el-input
                  style="width: 180px"
                  v-model="form_val.menu_input3"
                  @input="dad_input(3)"
                  :maxlength="4"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="line_div"></div>
          </div>
          <div
            class="dad_div"
            :style="{ height: input_show == 1 ? '300px' : '350px' }"
            v-show="parent_add == 0 && child_id == 1"
          >
            <h1>子菜单信息</h1>
            <el-form
              ref="ruleForm"
              label-width="60px"
              style="margin-top: 20px"
              :model="form_val"
            >
              <el-form-item label="名称" prop="name">
                <el-input
                  style="width: 180px; margin-left: 40px"
                  v-model="form_val.zi_name"
                  @input="input"
                  :maxlength="8"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="消息类型"
                label-width="80px"
                style="margin-top: 10px; margin-left: 5px"
              >
                <el-radio
                  @change="sexChange"
                  v-model="radio"
                  label="1"
                  style="margin-left: 15px"
                  >跳转网页</el-radio
                >
                <el-radio @change="sexChange" v-model="radio" label="2"
                  >跳转小程序</el-radio
                >
              </el-form-item>
              <el-form-item
                label="网页链接"
                label-width="80px"
                style="margin-top: 10px; margin-left: 5px"
                v-show="input_show == 1"
              >
                <el-input
                  style="width: 180px; margin-left: 17px"
                  v-model="form_val.wy"
                  @input="url_input"
                ></el-input>
              </el-form-item>
              <div v-show="input_show == 2">
                <el-form-item
                  label="appid"
                  label-width="66px"
                  style="margin-top: 19px"
                >
                  <el-input
                    style="width: 180px; margin-left: 35px"
                    v-model="form_val.wx"
                    @input="wx_appid"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="pagepath"
                  label-width="90px"
                  style="margin-top: 19px"
                >
                  <el-input
                    style="width: 180px; margin-left: 12px"
                    v-model="form_val.wx_se"
                    @input="wx_secret"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="line_div"></div>
            <!-- <div class="menu_underdiv" style="margin-top: 15px">
                    <el-button style="width: 100px">预览</el-button>
                  </div> -->
          </div>
          <div class="button_div_bottom">
            <el-button
              class="btn_el"
              style="border: none; background: #17a2b8"
              @click="child_click"
              >保存并发布</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="群发消息"
    width="700px"
  >
    <div class="main">
      <div class="main_right">
        <p style="margin-left: 20px; margin-bottom: 10px">发送给</p>
        <el-scrollbar height="400px"> </el-scrollbar>
        <div style="margin: 10px 0 10px 20px">
          <el-input
            size="small"
            style="width: 295px"
            v-model="allMessage"
          ></el-input>
        </div>
        <div style="text-align: right">
          <el-button size="small">取消</el-button>
          <el-button size="small" type="primary" @click="senMsg"
            >发送</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog title="绑定公众号" v-model="dialogVisiblebind" width="30%">
    <el-input
      placeholder="请输入完整的微信昵称"
      v-model="inputbind"
      clearable
      class="elInput"
      size="medium"
      style="width: 200px !important"
      @input="InputContent"
    >
    </el-input>
    <div class="fxk_div">
      <el-checkbox-group
        v-model="checkList"
        class="big_box"
        @change="handleCheckedCitiesChange"
      >
        <div class="box" v-for="(item, index) in arr" :key="index">
          <el-checkbox :label="item.nickname"></el-checkbox>
          <img
            :src="item.avatar_url"
            alt=""
            style="width: 40px; height: 40px; margin-left: 10px"
            class="choose_img"
          />
        </div>
      </el-checkbox-group>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisiblebind = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="ok_btn" size="small">
          绑定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!--  -->
  <el-dialog
    title="绑定公众号"
    v-model="dialogVisiblelist"
    width="30%"
    style="height: 700px"
  >
    <div style="display: flex; align-items: center">
      <el-input
        placeholder="搜索公司名"
        style="width: 270px; height: 30px"
        class="filter-item"
        size="small"
        v-model="elinputvalue"
      />
      <el-button
        type="primary"
        size="small"
        style="height: 30px"
        @click="getBindWechatCompany()"
      >
        搜索
      </el-button>
    </div>
    <div style="height: 100%">
      <div class="list_list" style="height: 500px">
        <el-scrollbar height="500px">
          <div class="divList" v-for="(item, index) in listarr" :key="index">
            <span>{{ item.name }}</span>
            <div class="da_loucm" v-show="item.user != null">
              <div
                class="loucmn"
                v-for="(itemes, indexes) in item.user"
                :key="indexes"
              >
                <img
                  :src="itemes.avatarUrl"
                  alt=""
                  style="width: 30px; height: 30px"
                />
              </div>
              <!-- <span v-show="item.user.length > 4"
                >...</span
              > -->
            </div>
            <el-button type="primary" size="small" @click="clickbind(item)">
              绑定人员
            </el-button>
            <!-- <div class="line_bind"></div> -->
          </div>
        </el-scrollbar>
      </div>
      <div class="pagination">
        <qzf-pagination
          v-show="total2 > 0"
          :total="total2"
          v-model:page="page"
          v-model:limit="limit"
          @pagination="getBindWechatCompany"
        />
      </div>
    </div>
  </el-dialog>
  <el-dialog title="温馨提示" v-model="showhintdisalog" width="30%">
    <p>一个用户一个月仅能接收4次群发消息</p>
    <p style="margin-top: 20px">
      在返回成功时，意味着群发任务提交成功，并不意味着此时群发已经结束，所以，仍有可能在后续的发送过程中出现异常情况导致用户未收到消息，如微信方会对消息有时会进行审核、服务器不稳定等。此外，群发任务一般需要较长的时间才能全部发送完毕，请耐心等待!
    </p>
  </el-dialog>

  <!-- <div class="no_bind">
    <div class="main_left">
      <img src="../../assets/gzh.png" alt="" />
      
    </div>
    <div class="main_rights">
      <h3>
        您的公众号<span style="color: var(--themeColor, #17a2b8)"
          >未绑定</span
        >
      </h3>
      <h5>微信公众号价值亮点</h5>
      <div class="each_contents">
        <div>
          <el-icon><circle-check-filled /></el-icon>
        </div>
        <div>
          <p>提高工作效率</p>
          <p>员工登录公众号后，欠费催款、客户跟进、外勤任务都可在手机端轻松搞定</p>
        </div>
      </div>
      <div class="each_contents">
        <div>
          <el-icon><circle-check-filled /></el-icon>
        </div>
        <div>
          <p>减少沟通成本</p>
          <p>客户登录公众号后，可自助查询财务数据、服务进度、确认税金，沟通成本大幅减少</p>
        </div>
      </div>
      <div class="each_contents">
        <div>
          <el-icon><circle-check-filled /></el-icon>
        </div>
        <div>
          <p>支持微信收款</p>
          <p>可通过微信收款，与鲸算盘无缝对接，从此对账不再烦恼</p>
        </div>
      </div>
      <p style="margin-left:20px;color:#17a2b9;font-size:15px;">了解详情</p>
      <el-button type="primary" style="margin-left:20px;margin-top:20px;" @click="go_sz">设置公众号<el-icon style="margin-left:10px;"><Right /></el-icon></el-button>
    </div>
    <div class="pics">
      <img src="../../assets//gzhbottom.png" alt="" />
    </div>
  </div> -->
</template>

<script>
import {
  getPublicParams,
  updatePublucParams,
  savePublucParams,
  initMenu,
  sendTemplateMsg,
  getMenu,
  sendMassMsg,
  sendCustomerMessage,
  getGzhInfo,
  getServerStatus
} from "@/api/gzh";
import {
  companySendWx,
  getMsgTemplate,
  getMsgHistory,
  getBindUserList,
  getBindWechatCompany,
  confirmBind,
  cannelBind,
} from "@/api/company";
import upload from "@/components/uploadImg/upload.vue";
// import { sendTemplateMsg } from "@/api/public";
import axios from "axios";
import { Header } from "element-plus/es/components/table-v2/src/components";

export default {
  name: "pushInfomation",

  data() {
    return {
      showhintdisalog: false,
      elinputvalue: "",
      total2: "",
      radio1: "",
      dialogVisiblelist: false,
      dialogVisiblebind: false,
      checkList: [],
      btn_type: 0,
      choose_show: 0,
      contractImg: "",
      under_divNumber: 0,
      form_val: {
        zi_name: "子菜单名称",
        wy: "",
        wx: "",
        wx_se: "",
        menu_input: "", //父级菜单数据绑定
        menu_input2: "", //父级菜单数据绑定
        menu_input3: "", //父级菜单数据绑定
      },
      radio: "1",
      inputbind: "",
      name_val1: "菜单名称",
      editableTabsValue: "2",
      divnum: 0,
      tabIndex: 2,
      timer: "", //展示的时间
      tableData: [],
      contentStyleObj: {},
      top: 0,
      left: 0,
      windowHeight: "", //可视高度
      windowWeight: "", //可视宽度
      listQuery: {
        // page: 1,
        // limit: 10,
        name: "",
      },
      form: {
        keyword1: "",
        keyword2: "",
      },
      form2: {
        keyword1: "",
        keyword2: "",
        keyword3: "",
      },
      bindform: {
        keyword1: "",
        keyword2: "",
        keyword3: "",
      },
      correct: false,
      total: 0,
      formLabelWidth: "120px",
      dialogFormVisible: false,
      pushName: "",
      pushOpenId: undefined,
      companyName: "",
      sels: [],
      allotPush: false,
      templatearr: [],
      number: "",
      comid: "",
      historyarr: [],
      showhide: 0,
      looknum: 0,
      template_id: "",
      oppenidarr: [],
      bindshow: 0,
      bindlist: {},
      title: "",
      divarr: [
        {
          name: "菜单名称",
          sub_button: [],
        },
      ],
      parent_add: 1,
      child_id: 0,
      add_show: 1,
      childaddnum: 1,
      childaddnum2: 1,
      childaddnum3: 1,
      childnum: 0,
      input_show: 1,
      click_number: 0,
      visible: false,
      str: "",
      text1_one: "",
      currentIndex: -1,
      arrList: {
        button: [],
      }, //放置所有内容的数组
      rules: {
        menu_input: [
          { min: 1, max: 4, message: "名称长度在1到4个字符", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value) == false) {
                callback(new Error("只能输入中英文或数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        menu_input2: [
          { min: 1, max: 4, message: "名称长度在1到4个字符", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value) == false) {
                callback(new Error("只能输入中英文或数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        menu_input3: [
          { min: 1, max: 4, message: "名称长度在1到4个字符", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value) == false) {
                callback(new Error("只能输入中英文或数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        zi_name: [
          { min: 1, max: 4, message: "名称长度在1到4个字符", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4E00-\u9FA5A-Za-z0-9]+$/.test(value) == false) {
                callback(new Error("只能输入中英文或数字"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      ruleForm: {
        gender: "",
      },
      selectedTag: {},
      all_arr: [],
      contentStyleObj1: {},
      loading: false,
      dialogVisible: false,
      list: [],
      checkedTemp: [],
      allMessage: "",
      customerMsgStatus: false,
      pic: "https://file.listensoft.net/mnt/down/avatar.jpg",
      type: "msg",
      templateData: [],
      itemName: "",
      appId: "",
      textarea_val: "",
      templateshow: "",
      choose_arr: [], //选中的名字
      arr: [],
      page: 1,
      limit: 20,
      listarr: [],
      bindid: "",
      bindname: "",
      state1:'',
      state2:''
    };
  },
  components: {
    upload,
  },
  created() {
    const pageHeight = document.documentElement.clientHeight;
    const pageWeight = document.documentElement.clientWidth;
    this.windowHeight = pageHeight;
    this.windowWeight = pageWeight / 2;
    this.contentStyleObj = this.$getHeight(210);
    this.contentStyleObj1 = this.$getHeight(443);
    this.getMsgTemplates();
    this.getInfo();
    this.getList();
    this.time_func();
    this.getBindWechatCompany();
    //this.getServerStatusS()
  },
  mounted() {
    // document.addEventListener("click", this.clear);
    if (this.dialogVisiblelist == false) {
      this.elinputvalue = "";
    }
  },
  beforeUnmount() {
    // document.removeEventListener("click", this.clear);
  },
  methods: {
    go_sz(){
      this.state1=1;
      this.dialogFormVisible=true;
    },
    dad_input(index) {
      if (index == 1) {
        this.divarr[0].name = this.form_val.menu_input;
      } else if (index == 2) {
        this.divarr[1].name = this.form_val.menu_input2;
      } else {
        this.divarr[2].name = this.form_val.menu_input3;
      }
      console.log(this.divarr);
      this.arrList.button = this.divarr;
    },
    showhint() {
      this.showhintdisalog = true;
    },
    dialogVisiblelistclick() {
      this.dialogVisiblelist = true;
      this.elinputvalue = "";
      this.getBindWechatCompany();
    },
    clearclose(row) {
      //console.log(row);
      this.$confirm("此操作将解除绑定, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cannelBind({ openid: [row.openid] }).then((res) => {
          if (res.data.errNo == 0) {
            this.$qzfMessage("解除成功!");
            this.getList();
            this.getBindWechatCompany();
          }
        });
      });
    },
    clickbind(item) {
      //console.log(item);
      this.bindid = item.ID;
      this.dialogVisiblebind = true;
      this.bindname = item.name;
    },
    getServerStatusS(){
      getServerStatus({}).then(res=>{
        console.log(res);
        if (res.data.errNo==0) {
          this.state1=res.data.data.gzhStatus;
          this.state2=res.data.data.qywxStatus;
        }
      })
    },
    getBindWechatCompany() {
      getBindWechatCompany({
        page: this.page,
        limit: this.limit,
        name: this.elinputvalue,
      }).then((res) => {
        //console.log(res);
        if (res.data.errNo == 0) {
          this.listarr = res.data.data.list;
          this.total2 = res.data.data.totle;
        }
      });
    },
    ok_btn() {
      if (this.choose_arr.length == 0) {
        this.$qzfMessage("请选择绑定人员", 1);
        return;
      }
      let arr = [];
      this.arr.forEach((element) => {
        for (let i = 0; i < this.choose_arr.length; i++) {
          if (element.nickname == this.choose_arr[i]) {
            arr.push(element.id);
          }
        }
      });
      confirmBind({ userId: arr, comId: this.bindid }).then((res) => {
        if (res.data.errNo == 0) {
          this.$qzfMessage("绑定成功");
          this.inputbind = "";
          this.elinputvalue = "";
          this.dialogVisiblebind = false;
          this.getBindWechatCompany();
          this.getList();
          // this.dialogVisiblebinding = false;
          // this.request();
          this.getBindUserList("");
        }
      });
    },
    InputContent(e) {
      //输入内容
      this.getBindUserList(e);
    },
    getBindUserList(e) {
      getBindUserList({ nickname: e }).then((res) => {
        if (res.data.errNo == 0) {
          this.arr = res.data.data;
        }
      });
    },
    handleCheckedCitiesChange(e) {
      //选择了哪些
      this.choose_arr = e;
    },
    findlist() {
      this.getList();
    },
    btn_choose(index) {
      this.choose_show = index;
      this.btn_type = index;
    },
    imgUrl(val) {
      console.log(val);
      this.contractImg = val;
    },
    dad_click(index, item) {
      if (index == 1) {
        this.form_val.menu_input = item.name;
      } else if (index == 2) {
        this.form_val.menu_input2 = item.name;
      } else {
        this.form_val.menu_input3 = item.name;
      }
    },
    getInfo() {
      getGzhInfo({}).then((res) => {
        this.pic = res.data.data;
      });
    },
    look() {
      if (this.bindlist != {}) {
        this.btn_type = 0;
      } else {
        this.btn_type = 1;
      }
      getMenu({ appid: this.bindlist.appId }).then((res) => {
        if (res.data.errNo == 0 && res.data.data) {
          if (
            JSON.parse(res.data.data) != [] &&
            JSON.parse(res.data.data) != null
          ) {
            this.divarr = JSON.parse(res.data.data);
            console.log(this.divarr);
            this.arrList.button = this.divarr;
            this.parent_add = 0;
            if (this.divarr.length == 3) {
              this.add_show = 0;
            } else {
              this.add_show = 1;
            }
          }
        } else {
          this.btn_type = 1;
          this.choose_show = 1;
        }
      });
    },
    mouse(tag, index, e) {
      this.childnum = index;
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left;
      const offsetWidth = this.$el.offsetWidth;
      const maxLeft = offsetWidth - menuMinWidth;
      const left = e.clientX - offsetLeft + 130;
      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;

      this.visible = true;
      this.selectedTag = tag;
    },
    out() {
      this.visible = false;
    },
    deletes(item, index, number) {
      let num = "";
      let childnum = "";
      num = this.divnum;
      childnum = this.childnum;
      this.divarr[num].sub_button.splice(1, childnum);
      if (this.childnum == 0) {
        this.divarr[num].sub_button.shift();
      }
      this.visible = false;
    },
    del2(item, index) {
      console.log(index);
      if (index == 0) {
        if (this.divarr.length != 1) {
          this.divarr.shift();
          this.form_val.menu_input3 = "";
          if (this.divarr.length == 2) {
            this.form_val.menu_input = this.divarr[0].name;
            this.form_val.menu_input2 = this.divarr[1].name;
          }
          console.log(this.divarr);
        } else {
          this.$message({
            message: "最少保留一个菜单",
            type: "warning",
          });
        }
      } else if (index == 1) {
        if (this.divarr.length != 1) {
          this.divarr.splice(index, 1);
          this.form_val.menu_input3 = "";
          if (this.divarr.length == 2) {
            this.form_val.menu_input = this.divarr[0].name;
            this.form_val.menu_input2 = this.divarr[1].name;
          }
        } else {
          this.$message({
            message: "最少保留一个菜单",
            type: "warning",
          });
        }
      } else if (index == 2) {
        if (this.divarr.length != 1) {
          this.divarr.splice(this.divarr.length - 1, 1);
          this.form_val.menu_input3 = "";
          if (this.divarr.length == 2) {
            this.form_val.menu_input = this.divarr[0].name;
            this.form_val.menu_input2 = this.divarr[1].name;
          }
        } else {
          this.$message({
            message: "最少保留一个菜单",
            type: "warning",
          });
        }
      }
      if (this.divarr.length == 3) {
        this.add_show = 0;
      } else {
        this.add_show = 1;
      }
      if (this.divarr.length == 0) {
        this.parent_add = 1;
        this.divarr = [];
        let list = {
          name: "菜单名称",
          sub_button: [],
        };
        this.divarr.push(list);
      }
      this.visible = false;
      this.arrList.button = this.divarr;
    },
    wx_secret() {
      this.divarr[this.divnum].sub_button[this.childnum].pagepath =
        this.form_val.wx_se;
      this.arrList.button = this.divarr;
    },
    wx_appid() {
      this.divarr[this.divnum].sub_button[this.childnum].appid =
        this.form_val.wx;
      this.arrList.button = this.divarr;
    },
    url_input() {
      this.divarr[this.divnum].sub_button[this.childnum].url = this.form_val.wy;
      this.arrList.button = this.divarr;
    },
    menu_inputIn() {
      if (this.divnum == 0) {
        this.form_val.menu_input = this.divarr[0].name;
      }
    },
    input() {
      console.log(this.divarr);
      this.divarr[this.divnum].sub_button[this.childnum].name =
        this.form_val.zi_name;
      this.arrList.button = this.divarr;
      // console.log(this.divarr);
      // console.log(this.arrList);
    },
    chooseChild(index, number) {
      this.form_val.zi_name = "";
      this.form_val.wy = "";
      this.form_val.wx = "";
      this.form_val.wx_se = "";
      if (this.divarr[this.divnum].sub_button[this.childnum].type == "view") {
        this.radio = "1";
        this.input_show = 1;
      } else {
        this.radio = "2";
        this.input_show = 2;
      }
      this.form_val.zi_name = this.divarr[number].sub_button[index].name;
      this.form_val.wx_se = this.divarr[number].sub_button[index].pagepath;
      this.form_val.wx = this.divarr[number].sub_button[index].appid;
      this.form_val.wy = this.divarr[number].sub_button[index].url;
      this.child_id = 1;
      this.childnum = index;
      console.log(this.form_val.menu_input);
      if (this.form_val.menu_input != "") {
        this.divarr[0].name = this.form_val.menu_input;
      } else if (this.form_val.menu_input2 != "") {
        this.divarr[1].name = this.form_val.menu_input2;
      } else if (this.form_val.menu_input3 != "") {
        this.divarr[2].name = this.form_val.menu_input3;
      }
      this.arrList.button = this.divarr;
      console.log(this.divarr);
    },
    child_click() {
      console.log(this.arrList);
      initMenu(this.arrList).then((res) => {
        if (res.data.errNo == 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        }
      });
    },
    sexChange(e) {
      this.input_show = e;
      if (this.input_show == 1) {
        this.divarr[this.divnum].sub_button[this.childnum].type = "view";
      } else {
        this.divarr[this.divnum].sub_button[this.childnum].type = "miniprogram";
      }
    },
    add_child() {
      if (this.divnum == 0) {
        let list = {
          name: "子菜单名称",
          type: "view",
          url: "",
          appid: "",
          pagepath: "",
        };
        this.divarr[0].sub_button.push(list);
        if (this.divarr[0].sub_button.length == 5) {
          this.childaddnum = 0;
        }
      } else if (this.divnum == 1) {
        let list = {
          name: "子菜单名称",
          type: "view",
          url: "",
          appid: "",
          pagepath: "",
        };
        this.divarr[1].sub_button.push(list);
        if (this.divarr[1].sub_button.length == 5) {
          this.childaddnum2 = 0;
        }
      } else {
        let list = {
          name: "子菜单名称",
          type: "view",
          url: "",
          appid: "",
          pagepath: "",
        };
        this.divarr[2].sub_button.push(list);
        if (this.divarr[2].sub_button.length == 5) {
          this.childaddnum3 = 0;
        }
      }
    },
    choose_div(index) {
      this.divnum = index;
      this.child_id = 0;
      this.childnum = 0;
      console.log(this.divarr);
    },
    add_divclick() {
      this.parent_add = 0;
      this.form_val.menu_input = "";
      this.form_val.menu_input2 = "";
      this.form_val.menu_input3 = "";
      console.log(this.divarr);
      this.arrList.button = this.divarr;
    },
    add_divclick2() {
      let list = {
        name: "菜单名称",
        sub_button: [],
      };
      this.divarr.push(list);
      if (this.divarr.length == 3) {
        this.add_show = 0;
      }
      this.arrList.button = this.divarr;
      console.log(this.arrList);
    },
    update_wx(index, item) {
      this.bindshow = index;
      this.itemName = item;
      this.bindform.keyword1 = this.bindlist.appId;
      this.bindform.keyword2 = this.bindlist.secret;
      this.bindform.keyword3 = this.bindlist.token;
    },
    update_ok(index) {
      console.log(this.bindshow);
      if (this.bindshow == 1) {
        updatePublucParams({
          id: this.bindlist.id,
          app_id: this.bindform.keyword1,
          secret: this.bindform.keyword2,
          token: this.bindform.keyword3,
          avatarUrl: this.bindlist.avatarUrl,
        }).then((res) => {
          if (res.data.errNo == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.dialogFormVisible = false;
            this.bindform.keyword1 = "";
            this.bindform.keyword2 = "";
            this.bindform.keyword3 = "";
          } else {
          }
        });
      } else {
        savePublucParams({
          app_id: this.bindform.keyword1,
          secret: this.bindform.keyword2,
          token: this.bindform.keyword3,
          avatarUrl: this.contractImg,
        }).then((res) => {
          if (res.data.errNo == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.bindform.keyword1 = "";
            this.bindform.keyword2 = "";
            this.bindform.keyword3 = "";
            this.dialogFormVisible = false;
          } else {
          }
        });
      }
    },
    install_func() {
      this.look();
      getPublicParams().then((res) => {
        if (res.data.errNo == 0) {
          this.dialogFormVisible = true;
          this.bindlist = res.data.data;
          if (this.bindlist.avatarUrl != "") {
            this.bindshow = 1;
            this.bindform.keyword1 = this.bindlist.appId;
            this.bindform.keyword2 = this.bindlist.secret;
            this.bindform.keyword3 = this.bindlist.token;
            // if (this.bindlist.appId == "") {
            //   this.bindshow = 2;
            // }
          } else {
            this.bindshow = 0;
          }
        }
      });
    },
    look_more2() {
      this.looknum = 0;
    },
    look_more() {
      this.looknum = 1;
    },
    click_choose(item, index) {
      this.title = item.nickname + "-" + item.name;
      this.currentIndex = index;
      this.oppenidarr = [];
      this.oppenidarr.push(item.openid);
      this.pushOpenId = item.openid;
      let i = item;
      this.comid = i.comId;
      this.getMsgHistorys();
      this.under_divNumber = 1;
    },
    getMsgHistorys() {
      getMsgHistory({ comId: this.comid, openid: this.oppenidarr[0] }).then(
        (res) => {
          if (res.data.errNo == 0) {
            this.historyarr = res.data.data.history;
            this.historyarr.forEach((element) => {
              element.createdAt =
                String(element.createdAt).substring(0, 10) +
                " " +
                String(element.createdAt).substring(11, 19);
              if (element.msgType == "template_msg") {
                element.message = JSON.parse(element.message);
              } else {
                element.message = element.message;
              }
              //
              if (element.templateName == "消息推送") {
                this.showhide = 0;
              } else {
                this.showhide = 1;
              }
            });
            this.customerMsgStatus = res.data.data.customerMsgStatus;
            console.log(this.historyarr);
          }
        }
      );
    },
    choose(item, index) {
      //console.log(this.templatearr);
      //console.log(this.templateData);
      this.template_id = item.templateId;
      this.number = index;
    },
    choose2(item, index) {
      this.template_id = item.templateId;
      this.number = 2;
      this.type = "msg";
    },
    time_func() {
      let now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hours = now.getHours();
      var minutes = now.getMinutes();
      var seconds = now.getSeconds();
      this.timer =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
    },
    click(e) {},
    getList() {
      this.loading = true;
      companySendWx(this.listQuery).then((res) => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
        this.list = this.tableData;
        if (this.tableData && this.tableData.length != 0) {
          this.title =
            this.tableData[0].nickname + "-" + this.tableData[0].name;
          this.currentIndex = 0;
          this.oppenidarr = [];
          this.oppenidarr.push(this.tableData[0].openid);
          this.pushOpenId = this.tableData[0].openid;
          this.comid = this.tableData[0].comId;
          this.under_divNumber = 1;
          this.getMsgHistorys();
        }
      });
    },
    getMsgTemplates() {
      getMsgTemplate().then((res) => {
        if (res.data.errNo == 0) {
          this.templatearr = [];
          this.templatearr = res.data.data;
          this.templatearr.forEach((element) => {
            element.templateStr = JSON.parse(element.templateStr);
          });
          //console.log(this.type);
          if (this.type == "msg") {
            this.templatearr.push({
              templateName: "消息回复",
              templateId: "xxxxx",
            });
          }
          this.number = 0;
          this.template_id = this.templatearr[0].templateId;
        }
      });
    },
    pushInform(row, item) {
      this.companyName = item.name;
      this.pushName = row.nickName;
      this.pushOpenId = [row.gzhOpenId];
      this.dialogFormVisible = true;
      this.allotPush = false;
    },
    itemlick(item, index) {
      // //console.log(item);
      // //console.log(index);
    },
    makeSurePush() {
      if(!this.form.keyword1 || !this.form.keyword2){
        this.$qzfMessage("请输入完整",1);
        return
      }
      let param = {};
      // if (this.template_id == "aX6shON9RRGod4zybHbh8mfsi6Dw_L-Z3IrGQFJf0RQ") {
      param = {
        comId: this.comid,
        // touser:'oB4P86jmohb-HnuqmFZ-ruVUCqxo',
        touser: this.oppenidarr,
        template_id: this.template_id,
        data: {
          thing2: {
            value: this.form.keyword1,
          },
          thing5: {
            value: this.form.keyword2,
          },
        },
      };
      // } else {
      //   param = {
      //     comId: this.comid,
      //     touser: this.oppenidarr,
      //     template_id: "Dwemscd2pXFITPOfyKptEqingGrfpXDpxGKZDXHY6hU",
      //     data: {
      //       keyword1: {
      //         value: this.form2.keyword1,
      //       },
      //       keyword2: {
      //         value: this.form2.keyword2,
      //       },
      //       keyword3: {
      //         value: this.form2.keyword3,
      //       },
      //     },
      //   };
      // }
      sendTemplateMsg(param).then((res) => {
        if (res.data.msg == "success") {
          this.$message({
            type: "success",
            message: res.data.data,
          });
          this.form2.keyword1 = "";
          this.form2.keyword2 = "";
          this.form2.keyword3 = "";
          this.form.keyword1 = "";
          this.form.keyword2 = "";
          this.getMsgHistorys();
        }
      });
    },
    //多选
    handleSelectionChange(val) {
      this.sels = val;
    },
    batchSend() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let status = false;
      this.sels.map((v) => {
        v.peoples.map((e) => {
          if (!e.unionid) {
            status = true;
            return;
          }
        });
      });
      if (status) {
        this.$qzfMessage("公司中存在人员未绑定小程序", 1);
        return;
      }
      let gzhStatus = false;
      this.sels.map((v) => {
        v.peoples.map((e) => {
          if (!e.gzhOpenId) {
            gzhStatus = true;
            return;
          }
        });
      });
      if (gzhStatus) {
        this.$qzfMessage("公司中存在人员未关注公众号", 1);
        return;
      }
      this.allotPush = true;
      let ids = [];
      this.sels.map((v) => {
        v.peoples.map((e) => {
          ids.push(e.gzhOpenId);
        });
      });
      this.pushOpenId = ids;
      this.dialogFormVisible = true;
    },
    sendSimpleMsg() {
      if (this.checkedTemp.length == 0) {
        this.$qzfMessage("请选择发送人员", 1);
        return;
      }
      let ids = [];
      this.checkedTemp.map((v) => {
        ids.push(v.openid);
      });
      sendMassMsg({
        users: ids,
        content: this.form.keyword1,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("发送成功");
          this.checkedTemp = [];
          this.form.keyword1 = "";
        }
      });
    },
    makeSurePushKf() {
      if (!this.textarea_val) {
        this.$qzfMessage("请输入发送信息", 1);
        return;
      }
      let param = {
        user: this.oppenidarr[0],
        content: this.textarea_val,
      };
      sendCustomerMessage(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("发送成功");
          this.form.keyword1 = "";
          this.textarea_val = "";
          this.getMsgHistorys();
        }
      });
    },
    switchType(type) {
      this.type = type;
      //console.log(this.templatearr);
      if (type == "person") {
        this.templateData = [];
        this.templateData.push(this.templatearr[0]);
        this.templateData.splice(2);
        this.templateData.push({
          templateName: "群发消息",
          templateId: "xxxxx",
        });
      } else {
        this.getMsgTemplates();
      }
    },
    sendMsgPersonTemplate() {
      console.log(this.checkedTemp);
      let param = {};
      if (this.checkedTemp.length == 0) {
        this.$qzfMessage("请选择发送人员", 1);
      }
      if (this.form.keyword1==''||this.form.keyword=='') {
        this.$qzfMessage("标题内容不可为空", 1);
      }
      let ids = [];
      this.checkedTemp.map((v) => {
        ids.push(v.openid);
      });
      if (this.template_id == "aX6shON9RRGod4zybHbh8mfsi6Dw_L-Z3IrGQFJf0RQ") {
        param = {
          touser: ids,
          template_id: "aX6shON9RRGod4zybHbh8mfsi6Dw_L-Z3IrGQFJf0RQ",
          data: {
            keyword1: {
              value: this.form.keyword1,
            },
            keyword2: {
              value: this.form.keyword2,
            },
          },
        };
      } else {
        param = {
          touser: ids,
          template_id: "F7mRSixU6tJdnBNWO9WBhM-iGp--3gejw9w9F16Bzyg",
          data: {
            thing2: {
              value: this.form.keyword1,
            },
            thing5: {
              value: this.form.keyword2,
            }
          },
        };
      }
      sendTemplateMsg(param).then((res) => {
        if (res.data.msg == "success") {
          this.form2.keyword1 = "";
          this.form2.keyword2 = "";
          this.form2.keyword3 = "";
          this.form.keyword1 = "";
          this.form.keyword2 = "";
          this.$qzfMessage(res.data.data);
          this.checkedTemp = [];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-checkbox__label) {
  padding-left: 2px;
  border-bottom: 1px solid #efefef;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
}
.div {
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.z_div {
  display: flex;
  width: 1130px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  // height: 790px;
  //justify-content: space-between;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.right_div {
  // border: 1px solid;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.on_val_div {
  // border: 1px solid red;
  // height: 500px;
  // overflow: scroll;
  // overflow-x: hidden;
  // margin-top: 10px;
  background-color: #f5f5f5;
  padding-bottom: 10px;
  border-left: 1px solid #efefef;
}
.on_clounm {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.newsdiv {
  display: flex;
  flex-direction: column;
  // margin-right: 20px;
}
.left_clounm {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  height: 100%;
  width: 100%;
}
.right_cloum {
  display: flex;
  flex-direction: column;
  border: 1px solid green;
  height: 100%;
  width: 100%;
}
.time_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  p {
    color: #fff;
    padding: 2px;
    font-size: 12px;
    background-color: #dadada;
    border-radius: 2px;
  }
}

.dives {
  width: 400px;
  height: 100%;
  // margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  border-radius: 4px;
}
.title_p {
  font-size: 15px;
  margin-top: 10px;
  padding-left: 20px;
  font-weight: 550;
}
.val_p {
  font-size: 13px;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
}
.cutu_span {
  cursor: pointer;
}
// .active {
//   pointer-events: none;
// }
.span_val {
  display: inline-block;
  width: 300px;
  word-wrap: break-word;
  font-weight: 520;
  font-family: "Helvetica Neue", sans-serif;
}
.under_div {
  border: 1px solid #efefef;
  padding-bottom: 15px;
  height: 250px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: none;
}
.under_on_div {
  // border: 1px solid yellow;
  height: 30px;
  display: flex;
  align-items: center;
}
.hint {
  font-size: 13px;
  margin-left: 30px;
  // color: var(--themeColor, #17a2b8);
  font-family: "Helvetica Neue", sans-serif;
  cursor: pointer;
}
.all_div {
  display: flex;
  justify-content: space-between;
}
.left_div {
  display: flex;
  flex-direction: column;
}
.h_div {
  display: flex;
  align-items: center;
  margin-left: 10px;
  //margin-top: 10px;
}
.inout {
  width: 300px;
  padding-top: 15px;
  margin-left: 10px;
}
.list_left_div {
  // border: 1px solid;
}
.list_left_divactive {
  border: 1px solid #f5f5f5;
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.activeEs {
  height: 250px;
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.choose_list {
  //height: 690px;
  // overflow: scroll;
  // overflow-x: hidden;
}
.list_div {
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 300px;
  //width: 400px;
  // height: 100%;
  //margin-top: 10px;
  margin-left: 15px;
  display: flex;
  // flex-direction: column;
  //padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f_line {
  width: 100%;
  height: 3px;
  background-color: black;
}
.span_p {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 15px;
  font-family: monospace;
  font-weight: 550;
}
.div_box {
  display: flex;
  align-items: center;
}
.flex_image_div {
  display: flex;
  width: 30px;
  height: 30px;
  // border: 1px solid;
  margin-left: 20px;
  margin-top: 10px;
}
.image_box {
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  height: 100%;
}
.image_box2 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.name_span {
  font-size: 13px;
  margin-left: 10px;
}
.active {
  display: flex;
  width: 70px;
  height: 60px;
  margin-left: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 3px;
  overflow: hidden;
}
.newactive {
  display: flex;
  width: 70px;
  margin-left: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 3px;
  overflow: hidden;
  height: 100%;
}
.series {
  display: flex;
  flex-direction: column;
}
.button_div {
  width: 70px;
  margin-top: 10px;
  margin-left: 20px;
}
.add_class {
  margin-left: 40px;
}
.right_dives {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.btn_button {
  width: 50px;
  height: 50px;
  display: flex;
  // flex-wrap: wrap;
  background-color: #17a2b8;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
}
.btn_button span {
  margin-left: 7px;
  font-size: 12px;
}
.right_div_buttondiv {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.footer_div {
  display: flex;
  justify-content: flex-end;
  width: 1100px;
  padding-right: 20px;
}
.title_div {
  display: flex;
  justify-content: center;
  height: 21px;
  margin-top: 3px;
}
.underdiv {
  // border: 1px solid;
  width: 100%;
  height: 500px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.header_div {
  display: flex;
  justify-content: space-between;
}
.left_screen {
  height: 500px;
  width: 300px;
  border: 1px solid #e7e6e6;
}
.screen_header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.time {
  font-size: 14px;
  font-weight: bold;
}
.righhtdiv {
  display: flex;
  align-items: center;
}
.righhtdiv img {
  width: 20px;
  height: 20px;
}
.key_div {
  width: 300px;
  height: 50px;
  background: #fff;
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #edeaea;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.add_menu {
  border: 2px dashed rgba(7, 193, 96, 0.6);
  height: 48px;
  width: 250px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1px;
  cursor: pointer;
}
.add_div {
  width: 250px;
  margin-left: 20px;
  height: 48px;
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  border: 1px solid #e7e6e6;
  border-top: none;
  border-left: none;
  border-bottom: none;
  margin-right: 1px;
}
.lines {
  width: 1px;
  height: 25px;
  background-color: #e7e6e6;
}
.add_div_box {
  width: 100%;
  height: 50px;
  // background-color: rgba(7, 193, 96, 0.05);
  border: 1px solid #e7e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.divActive {
  background-color: rgba(7, 193, 96, 0.05);
  border: 1px solid #07c160;
  color: #07c160;
  position: relative;
  margin-bottom: -14px;
}
.add_div_box span {
  font-size: 13px;
}
.val_span {
  color: #07c160;
  font-size: 14px;
  margin-left: 5px;
}
.right_screen {
  margin-left: 20px;
  margin-top: 5px;
}
.no_menu_div {
  background-color: #fff;
  width: 750px;
  height: 160px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #ddd;
  padding-top: 30px;
}
.tit_span {
  margin-left: 70px;
}
.line_div {
  width: 750px;
  height: 1px;
  background-color: #c7c6c6;
  margin-top: 40px;
}
.menu_underdiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}
.btn_el {
  background-color: #07c160;
  color: #fff;
}
.dad_div {
  background-color: #fff;
  width: 760px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #ddd;
  padding-top: 30px;
}
.dad_div h1 {
  margin-left: 20px;
}
.input_div {
  display: flex;
  align-items: center;
}
.child_div {
  width: 100%;
  // border: 1px solid;
  height: 300px;
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.child_list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.triangle {
  width: 30px;
  height: 30px;
}
.sj {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 10px solid red;
  transform: rotate(45deg);
}
.child_box {
  width: 100%;
  height: 40px;
  border: 1px solid #e7e7eb;
  // margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.childclass {
  margin-bottom: 0;
  border-bottom: none;
  border: 1px solid #e7e7eb;
}
.button_div_bottom {
  position: absolute;
  // border: 1px solid;
  width: 780px;
  bottom: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 2147483647;
  // position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
.addclassname {
  display: none;
}
.heng {
  display: flex;
  align-items: center;
}
.shu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newshu {
  position: relative;
  top: 13px;
}
.com_style {
  width: 160px;
  // border: 1px solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}
.com_style2 {
  // border: 1px solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999;
  // margin-top: 5px;
}
.nick_name {
  // width: 240px;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #333;
}
.actived {
  background-color: #f5f5f5;
}
.dives_left {
  background-color: #95ec69;
  width: 300px;
}
.activedSpan {
  color: var(--themeColor, #17a2b8);
}
.left_fixed {
  width: 50px;
  background-color: #f5f5f5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
  text-align: center;
}
:deep(.el-checkbox) {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.main {
  display: flex;
}
:deep(.el-scrollbar) {
  height: auto;
}
.main_right {
  border-left: 1px solid #b9b9b9;
}
.span_class {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialog_btn_div {
  display: flex;
  align-items: center;
}
.textarea :deep(.el-textarea__inner) {
  height: 160px;
  overflow-y: auto;
  outline: none;
  border: none;
  resize: none;
  box-shadow: none;
  width: 720px;
}
.first_textarea :deep(.el-textarea__inner) {
  height: 85px;
  overflow-y: auto;
  outline: none;
  resize: none;
  width: 700px;
}
.second_textarea {
  height: 60px;
  overflow-y: auto;
  outline: none;
  resize: none;
  width: 700px;
}
.fxk_div {
  width: 100%;
}
.big_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
}
.box {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}
.choose_img {
  border-radius: 50px;
}
.list_list {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  // overflow: hidden;
  // overflow-y: scroll;
}
.divList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.da_loucm {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // border: 1px solid;
  width: 120px;
  height: 60px;
  overflow: hidden;
}
.loucmn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loucmn img {
  border-radius: 50px;
}
.line_bind {
  width: 600px;
  height: 1px;
  background-color: #e5e2e2;
}
.no_bind {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
  display: none;
}
.main_left {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
  }
}
.main_rights {
  float: left;
  h3 {
    margin-bottom: 15px;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  h5 {
    margin-bottom: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
  }
}
.pics {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: 0;
  img {
    width: 100%;
  }
}
.each_contents {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .el-icon {
    font-size: 20px;
    color: #72dd88;
    margin-right: 3px;
  }
  p {
    color: #474747;
    font-size: 14px;
  }
}
</style>
